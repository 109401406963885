export default function () {
  return {
    about_us: [],
    privacy_policy: [],
    terms_conditions: [],

    user: null,
    user_info: [],
    code: null,
    verify: [],
    subscription: [],
    settings: [],
    suppliers: [],

    factories: [],
    products: [],
    continents: [],
    subcountries: [],
    countries: [],
    asia_countries: [],
    africa_countries: [],
    europe_countries: [],
    australia_countries: [],
    northamerica_countries: [],
    southamerica_countries: [],
    oceania_countries: [],
    antarctica_countries: [],
    middle_countries: [],
    categories: [],
    sub_categories: [],
  };
}
