import { api } from "@/services/axios";

export default {
  async verifyContactNumberAction({ commit }, payload) {
    await api
      .post(`/verify-contact-number`, payload)
      .then((res) => {
        commit("codeMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
