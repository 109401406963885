import Vue from "vue";
import VueRouter from "vue-router";
import { BootstrapVue } from "bootstrap-vue";
import middlewarePipeline from "./middleware-pipeline";
import { middleware } from "./middleware";
Vue.use(BootstrapVue);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: () => import("@/components/pages/customer/index.vue"),
  },
  {
    path: "/categories",
    name: "CategoriesPage",
    component: () => import("@/components/pages/customer/Categories.vue"),
  },
  {
    path: "/sub-categories",
    name: "SubCategoryPage",
    component: () => import("@/components/pages/customer/SubCategories.vue"),
  },
  {
    path: "/factories",
    name: "FactoriesPage",
    component: () => import("@/components/pages/customer/Factories.vue"),
  },
  {
    path: "/factory-profile",
    name: "FactoryProfilePage",
    component: () => import("@/components/pages/customer/FactoryProfile.vue"),
  },
  {
    path: "/product-details",
    name: "ProductDetailsPage",
    component: () => import("@/components/pages/customer/ProductDetails.vue"),
  },
  {
    path: "/notifications",
    name: "NotificationsPage",
    component: () => import("@/components/pages/customer/Notifications.vue"),
  },
  {
    path: "/contact",
    name: "ContactPage",
    component: () => import("@/components/pages/customer/Contact.vue"),
  },
  {
    path: "/about",
    name: "AboutPage",
    component: () => import("@/components/pages/customer/About.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: () => import("@/components/pages/customer/PrivacyPolicy.vue"),
  },
  {
    path: "/terms-conditions",
    name: "TermsConditionsPage",
    component: () => import("@/components/pages/customer/TermsConditions.vue"),
  },

  {
    path: "/web/terms-conditions",
    name: "WebTermsConditionsPage",
    component: () => import("@/components/pages/web/TermsConditions.vue"),
  },

  {
    path: "/web/privacy-policy",
    name: "WebPrivacyPolicyPage",
    component: () => import("@/components/pages/web/PrivacyPolicy.vue"),
  },

  {
    path: "/supplier/login",
    name: "SupplierLoginPage",
    component: () => import("@/components/pages/supplier/Login.vue"),
  },
  {
    path: "/supplier/register-phone",
    name: "SupplierRegisterPhonePage",
    component: () => import("@/components/pages/supplier/RegisterPhone.vue"),
  },
  {
    path: "/supplier/confirm-payment",
    name: "SupplierConfirmPaymentPage",
    component: () => import("@/components/pages/supplier/ConfirmPayment.vue"),
  },
  {
    path: "/supplier/confirmation",
    name: "SupplierConfirmationPage",
    component: () => import("@/components/pages/supplier/Confirmation.vue"),
  },
  {
    path: "/supplier/register",
    name: "SupplierRegisterPage",
    component: () => import("@/components/pages/supplier/Register.vue"),
    // meta: {
    //   middleware: [middleware],
    // },
  },
  {
    path: "/supplier/subscription",
    name: "SupplierSubscriptionPage",
    component: () => import("@/components/pages/supplier/Subscription.vue"),
    meta: {
      middleware: [middleware],
    },
  },
  {
    path: "/supplier/set-profile",
    name: "SupplierSetProfilePage",
    component: () => import("@/components/pages/supplier/SetProfile.vue"),
    meta: {
      middleware: [middleware],
    },
  },
  {
    path: "/supplier/home",
    name: "SupplierHomePage",
    component: () => import("@/components/pages/supplier/Home.vue"),
    meta: {
      middleware: [middleware],
    },
  },
  {
    path: "/supplier/add-product",
    name: "SupplierAddProductPage",
    component: () =>
      import("@/components/pages/supplier/products/AddProduct.vue"),
    meta: {
      middleware: [middleware],
    },
  },
  {
    path: "/supplier/product/:id",
    name: "SupplierEditProductPage",
    component: () =>
      import("@/components/pages/supplier/products/EditProduct.vue"),
    meta: {
      middleware: [middleware],
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const ctx = { from, next, to };
    const nextMiddleware = middlewarePipeline(ctx, middleware, 1);

    return middleware[0]({ ...ctx, next: nextMiddleware });
  }
  return next();
});

export default router;
