import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import * as mutations from "./mutations";
import actions from "./actions";
import products from "./actions/products";
import categories from "./actions/categories";
import subcategories from "./actions/sub-categories";
import auth from "./actions/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions: {
    ...auth,
    ...actions,
    ...categories,
    ...products,
    ...subcategories,
  },
  modules: {},
});
