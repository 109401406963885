function middleware({ next }) {
  let token = localStorage.getItem("access_token");

  if (!token) {
    return next("/supplier/login");
  }

  return next();
}

export { middleware };
