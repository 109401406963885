export function continentsMutation(state, payload) {
  state.continents = payload;
}

export function countriesMutation(state, payload) {
  state.countries = payload;
}

export function subcountriesMutation(state, payload) {
  state.subcountries = payload;
}

export function categoriesMutation(state, payload) {
  state.categories = payload;
}

export function subCategoriesMutation(state, payload) {
  state.sub_categories = payload;
}

export function countriesAsiaMutation(state, payload) {
  state.asia_countries = payload;
}

export function countriesAfricaMutation(state, payload) {
  state.africa_countries = payload;
}

export function countriesEuropeMutation(state, payload) {
  state.europe_countries = payload;
}

export function countriesMiddleMutation(state, payload) {
  state.middle_countries = payload;
}

export function signinMutation(state, payload) {
  if (payload.access_token)
    localStorage.setItem("access_token", payload.access_token);
  state.user = payload;
  state.code = null;
}

export function signoutMutation(state) {
  localStorage.removeItem("access_token");
  state.user = null;
}

export function authMutation(state, payload) {
  state.user = payload;
}

export function countriesAustraliaMutation(state, payload) {
  state.australia_countries = payload;
}

export function countriesNorthAmericaMutation(state, payload) {
  state.northamerica_countries = payload;
}

export function countriesSouthAmericaMutation(state, payload) {
  state.southamerica_countries = payload;
}

export function countriesOceaniaMutation(state, payload) {
  state.oceania_countries = payload;
}

export function countriesAntarcticaMutation(state, payload) {
  state.antarctica_countries = payload;
}

export function productsMutation(state, payload) {
  state.products = payload;
}

export function codeMutation(state, payload) {
  state.code = payload;
}

export function verifyMutation(state, payload) {
  state.verify = payload;
}

export function subscriptionMutation(state, payload) {
  state.subscription = payload;
}

export function pageSettingsMutation(state, payload) {
  state.settings = payload;
}

export function suppliersMutation(state, payload) {
  state.suppliers = payload;
}

export function aboutUsMutation(state, payload) {
  state.about_us = payload;
}

export function privacyMutation(state, payload) {
  state.privacy_policy = payload;
}

export function termsMutation(state, payload) {
  state.terms_conditions = payload;
}
