import { api } from "@/services/axios";

export default {
  async getContinentsAction({ commit }) {
    await api
      .get(`/continents`)
      .then((res) => {
        commit("continentsMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },

  async getCountriesAction({ commit }) {
    await api
      .get(`/countries`)
      .then((res) => {
        commit("countriesMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },

  async getSubCountriesAction({ commit, dispatch }, payload) {
    let endpoint = `/continent-countries`;
    if (payload) {
      endpoint = `/continent-countries?query=${payload.continent}`;
    }
    await api
      .get(endpoint)
      .then((res) => {
        commit("subcountriesMutation", res.data.data);
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors);
      });
  },

  async getCountriesAsiaAction({ commit }) {
    await api
      .get(`/continent-countries?query=asia`)
      .then((res) => {
        commit("countriesAsiaMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },

  async getCountriesAfricaAction({ commit }) {
    await api
      .get(`/continent-countries?query=africa`)
      .then((res) => {
        commit("countriesAfricaMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },

  async getCountriesEuropeAction({ commit }) {
    await api
      .get(`/continent-countries?query=europe`)
      .then((res) => {
        commit("countriesEuropeMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },

  async getCountriesAustraliaAction({ commit }) {
    await api
      .get(`/continent-countries?query=australia`)
      .then((res) => {
        commit("countriesAustraliaMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },

  async getCountriesNorthAmericaAction({ commit }) {
    await api
      .get(`/continent-countries?query=north america`)
      .then((res) => {
        commit("countriesNorthAmericaMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },

  async getCountriesSouthAmericaAction({ commit }) {
    await api
      .get(`/continent-countries?query=south america`)
      .then((res) => {
        commit("countriesSouthAmericaMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },

  async getCountriesOceaniaAction({ commit }) {
    await api
      .get(`/continent-countries?query=oceania`)
      .then((res) => {
        commit("countriesOceaniaMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },

  async getCountriesAntarcticaAction({ commit }) {
    await api
      .get(`/continent-countries?query=antarctica`)
      .then((res) => {
        commit("countriesAntarcticaMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },

  async getCountriesMiddleAction({ commit }) {
    await api
      .get(`/continent-countries?query=middle east`)
      .then((res) => {
        commit("countriesMiddleMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },

  async getPageSettings({ commit }) {
    await api
      .get(`/page-settings`)
      .then((res) => {
        commit("pageSettingsMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },

  async getSuppliers({ commit }, payload) {
    let endpoint = `/suppliers`;
    if (payload) {
      endpoint = `/suppliers?country_id=${payload.country_id}&category_id=${payload.category_id}&sub_category_id=${payload.sub_category_id}`;
      // endpoint = `/suppliers?country_id=${payload.country_id}`;
    }

    await api
      .get(endpoint)
      .then((res) => {
        commit("suppliersMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },
  async searchSuppliers({ commit }, payload) {
    let endpoint = `/suppliers/search`;
    await api
      .post(endpoint, payload)
      .then((res) => {
        commit("suppliersMutation", { data: res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
