import { api } from "@/services/axios";

export default {
  async getSubCategoriesAction({ commit, dispatch }, payload) {
    let endpoint = `/sub-categories`;
    if (payload) {
      endpoint = `/sub-categories?category_id=${payload.category_id}`;
    }
    await api
      .get(endpoint)
      .then((res) => {
        commit("subCategoriesMutation", res.data.data);
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors);
      });
  },
};
