import axios from "axios";

let base = process.env.VUE_APP_API;

const api = axios.create({ baseURL: base });

api.interceptors.request.use((request) => {
  const token = localStorage.getItem("access_token");
  if (token) request.headers.common["Authorization"] = `Bearer ${token}`;
  request.headers.common["x-localization"] =
    localStorage.getItem("language") ?? "en";

  return request;
});

export default {
  install(Vue) {
    Vue.prototype.$api = api;
  },
};

export { api };
