import { api } from "@/services/axios";

export default {
  async getCategoriesAction({ commit }, payload) {
    let endpoint = `/categories`;
    if (payload) {
      endpoint = `/categories?page=${payload.page}`;
    }
    await api
      .get(endpoint)
      .then((res) => {
        commit("categoriesMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
