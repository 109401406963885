import { api } from "@/services/axios";

export default {
  async getProductsAction({ commit }, payload) {
    let endpoint = `/products`;
    if (payload) {
      endpoint = `/products?supplier_id=${payload.supplier_id}`;
    }
    await api
      .get(endpoint)
      .then((res) => {
        commit("productsMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async showProductAction({ commit }, payload) {
    await api
      .get(`/factory/products/${payload}`)
      .then((res) => {
        commit("productsMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
